@import url(https://fonts.googleapis.com/css?family=Didact+Gothic);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  color: #93a1a1;
  background-color: rgb(79, 79, 79);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.splash {
  text-align: left;
  padding-left: 2%;
  font-size: medium;
}

.center {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

social {
  margin: 20px;
  color: #93a1a1;
  text-align: center;
}

.fa-github:hover,
.fa-linkedin:hover,
.fa-envelope:hover {
  color: #b58900;
}

